<template>
  <v-container>
    <v-card>
      <v-data-table :search="search" v-bind="bind.table">
        <template #top>
          <div class="d-flex pa-3">
            <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
            <v-divider v-bind="bind.divider"></v-divider>
            <v-spacer></v-spacer>
            <v-btn v-bind="bind.refresh" v-on="on.refresh"
              ><v-icon>{{ icons.refresh }}</v-icon></v-btn
            >
            <v-btn v-bind="bind.new" v-on="on.new">{{ labels.new }}</v-btn>
          </div>
        </template>

        <template #item.actions="{ item }">
          <v-btn
            color="success"
            v-bind="bind.actions"
            @click="activateItem(item)"
            :disabled="!item.eliminado"
            ><v-icon>{{ icons.activate }}</v-icon></v-btn
          >

          <v-btn v-bind="bind.actions" v-on="on.edit(item)"
            ><v-icon>{{ icons.edit }}</v-icon></v-btn
          >
          <v-btn
            color="error"
            v-bind="bind.actions"
            v-on="on.delete(item)"
            :disabled="item.eliminado"
            ><v-icon>{{ icons.delete }}</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>

    <Modal v-model="dialog" v-bind="bind.form" v-on="on.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import Modal from "@/components/Modal/ModalProduct/Modal-Ramo";
import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "RamosCrudPage",
  mixins: [PageMixin],
  components: {
    Modal,
  },
  data: () => ({
    headers: [
      { text: "Codigo", value: "codigo", align: "left" },
      { text: "Descripción", value: "ramo_descripcion", align: "left" },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
    ramos: [],
  }),

  computed: {
    items() {
      return this.ramos;
    },
  },

  methods: {
    ...mapActions("NewProduct", ["getRamos", "deleteRamos", "ActivateRamo"]),

    async setup() {
      this.loading = true;
      try {
        const response = await this.getRamos();
        this.ramos = response;
      } catch (error) {
        this.snackbar(error.result?.message || error.info);
      }
      this.loading = false;
    },

    saveItem() {
      this.closeForm();
      this.setup();
    },

    deleteMethod() {
      return this.deleteRamos;
    },
    activateMethod() {
      return this.ActivateRamo;
    },
  },
};
</script>
