export const Bienes = [
  {
    text: "Automovil",
    value: "automovil",
  },
  {
    text: "Hogar",
    value: "hogar",
  },
];

export const companies = {
  zurich1: {
    code: 1,
  },
  zurich2: {
    code: 6,
  },
  hdi: {
    code: 62,
  },
};

export default {
  Bienes,
  companies,
};
