<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog" :persistent="loadingForm">
    <v-card>
      <v-card-title primary-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close" :disabled="loadingForm"
          ><v-icon>{{ icons.close }}</v-icon></v-btn
        >
      </v-card-title>

      <v-card-text>
        <v-form :ref="formRef">
          <v-row multi-line>
            <!-- CAMPO CODIGO -->
            <v-col cols="12" md="5">
              <v-text-field
                v-model="form.codigo"
                name="codigo"
                label="Código"
                placeholder="Escriba el código..."
                :rules="[(v) => !!v || 'Campo código requerido']"
              ></v-text-field>
            </v-col>

            <!-- CAMPO DESCRIPCION -->
            <v-col cols="12" md="7">
              <v-text-field
                v-model="form.ramo_descripcion"
                name="ramo"
                label="Ramo"
                placeholder="Escriba nombre del ramo"
                id="id_ramo"
                :rules="[(v) => !!v || 'Campo nombre ramo requerido']"
              >
              </v-text-field>
            </v-col>

            <!-- CAMPO CHECK BIEN ASEGURADO -->
            <v-col cols="12" md="6">
              <v-checkbox
                label="Habilitar bien asegurado"
                :true-value="true"
                :false-value="false"
                v-model="form.bien_asegurado"
              ></v-checkbox>
            </v-col>

            <!-- CAMPO CHECK OTROS REQUISITOS -->
            <v-col cols="12" md="6">
              <v-checkbox
                label="Habilitar otros requisitos"
                v-model="form.otros_requisitos"
                :true-value="true"
                :false-value="false"
              ></v-checkbox>
            </v-col>

            <!-- CAMPO SELECT BIEN ASEGURADO -->
            <v-col cols="12">
              <v-autocomplete
                :items="Bienes"
                v-model="form.formulario_bien_asegurado"
                label="Formulario del bien asegurado"
                :disabled="!form.bien_asegurado"
                :rules="[
                  (v) =>
                    (form.bien_asegurado ? !!v : true) ||
                    'Debe seleccionar un formulario de bien asegurado',
                ]"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit" :loading="loadingForm">{{
          labels.submit
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { Bienes } from "@/store/resources/staticData.js";

import FormMixin from "@/Mixins/SetFormMixin.js";

export default {
  name: "RamosFormModal",
  mixins: [FormMixin],
  data() {
    return {
      loadingForm: false,
      defaultTitle: "Formulario de ramo",
      form: {
        ramo_descripcion: "",
        codigo: "",
        bien_asegurado: false,
        formulario_bien_asegurado: "",
        otros_requisitos: false,
      },
    };
  },
  computed: {
    Bienes: () => Bienes,
  },
  methods: {
    ...mapActions("NewProduct", ["CreateRamo", "UpdateRamo"]),

    send() {
      this.loadingForm = true;
      let method = this.CreateRamo;
      const formData = { ...this.form };
      if (this.formData) {
        method = this.UpdateRamo;
        formData.id = this.formData[this.id];
      }
      method(formData)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.$emit("submit");
        })
        .catch((error) => {
          this.snackbar(error.result.message || error.info);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
  },
};
</script>
